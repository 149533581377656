/* eslint-disable import/no-extraneous-dependencies */
require('core-js/es/promise');
require('core-js/es/object/assign');

require('core-js/es/map');
require('core-js/es/set');
require('core-js/es/symbol');

require('core-js/es/array/find');
require('core-js/es/array/from');
require('core-js/es/array/keys');

require('core-js/es/number/is-integer');

require('core-js/es/string/ends-with');
require('core-js/es/string/includes');

require('core-js/stable');
require('intersection-observer');
